@import "bootstrap/dist/css/bootstrap.css";

/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "TradeGothicLTStd";
  src: url("./assets/fonts/TradeGothicLTStd-Cn18.otf") format("opentype");
}

@font-face {
  font-family: "TradeGothicLTStdBold";
  font-weight: bolder;
  src: url("./assets/fonts/TradeGothicLTStd-BdCn20.otf") format("opentype");
}

.spinner-border {
  margin-top: 10px;
  margin-bottom: 10px;
}

button.pccl-card {
  height: 40px !important;
  width: 40px !important;
}

mat-icon.pccl-card {
  height: 40px;
}

em {
  font-size: 11px;
  line-height: 14px;
  display: block;
  margin-bottom: -14px;
}

.back-white {
  background-color: #fff;
  box-shadow: none !important;
}
mat-icon {
  transform: scale(1.35)
}

mat-icon[svgIcon] {
  transform: scale(1.5)
}

.word-wrap {
  white-space: normal !important;
}

.font800 {
  font-weight: 800 !important;
}

.list-block {
  display: block !important;
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.caretExpanded {
  transform: rotate(180deg);
}

.mat-option-text.mat-option-text {
  white-space: normal;
}

.mat-select-placeholder {
  line-height: unset !important;
}

.mat-select-trigger, .mat-option {
  font-size: unset !important;
}

.mat-select-panel mat-option.mat-option {
  height: unset;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0px !important;
}

.tablist-no-click, .tablist-no-click a, .tablist-no-click li {
  cursor: default;
}

.modal-dialog {
  overflow-y: initial !important;
}

.modal-body {
  overflow-y: auto;
}

.modal-wrapper {
  width: 60vw;
}

.flex-end-item {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.paddingOneRem {
  padding: 1rem;
}

.comunication-dialog-title {
  font-size: 18px;
  font-weight: bold;
  color: #3f51b5;
}

.continue-reading {
  cursor: pointer;
  opacity: 0.6;
}

.pointer {
  cursor: pointer;
}

.png-to-icon {
  position: relative;
  left: 9px;
  top: -1px;
  width: 18px;
  height: 18px;
}

.custom-dialog-scroll {
  overflow-y: scroll !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

ngx-spinner>div>div.la-2x.la-ball-spin-clockwise {
  color: var(--brand-primary);
}

// MATERIAL FIX

app-landing {
  .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled.mdc-text-field--no-label {
    width: 300px;
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-form-field-flex {
    height: 32px;
  }
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
      .mdc-text-field--textarea
    )
    .mat-mdc-form-field-infix {
    padding-top: 6px;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled.mdc-text-field--no-label {
    background-color: #f96f2b;
  }

  .mat-mdc-select-value,
  .mat-mdc-select-placeholder {
    color: white;
  }

  // Mat-SELECT CUSTOM COLOR
  .mat-mdc-select-arrow {
    color: white;
  }
}
